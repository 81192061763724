function getCookie (vm) {
    const { cookies } = vm.$options
    if (cookies) {
      return typeof cookies === 'function'
        ? cookies.call(vm)
        : cookies
    }
  }
  
  
  export default {
    created () {
        const cookies = getCookie(this);
        const head = document.head;
        if (cookies) {
           cookies.forEach((cookie) => {
              if(cookie.script !== null && cookie.script !== ''){
                head.insertAdjacentHTML('beforeend', cookie.script);
              }
            })    
        }
    }
  }
<template>
  <div id="app">        
    <Suspense>
      <router-view :key="$route.fullPath"></router-view>    
    </Suspense>
  </div>

</template>

<script>


export default {
  name: "App"
};
</script> 
<style lang="styl">
</style>
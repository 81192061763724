function getTagsGTM (vm) {
  const { tags } = vm.$options
  if (tags) {
    return typeof tags === 'function'
      ? tags.call(vm)
      : tags
  }
}

export default {
  created () {
      const tags = getTagsGTM(this);
      const head = document.head;
      const body = document.body;  
      if (tags) {     
         tags.forEach((tag) => {
            // HEAD
            if(tag.head_gtm !== null && tag.head_gtm !== ''){
              const headGTM = document.createElement('script');
              headGTM.insertAdjacentHTML('afterbegin', tag.head_gtm);
              head.appendChild(headGTM);
            }

            // BODY
            if(tag.body_gtm !== null && tag.body_gtm !== ''){
              body.insertAdjacentHTML('afterbegin', tag.body_gtm);
            }
        })  
      }
  }
}
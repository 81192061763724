import { createApp, h } from "vue";
import { createRouter, createWebHistory } from "vue-router";


import App from "./App.vue";
import VueTheMask from 'vue-the-mask'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import titleMixin from "./mixins/mixinTitle";
import metaTagMixin from './mixins/mixinMetaTags';
import gtmTagsMixin from './mixins/mixinTagsGTM';
import Cookies from './mixins/mixinCookie';


const routes = [
  { 
    path: "/", 
    component: () => import("./containers/Home.vue")
  },
  { 
    path: "/:slug", 
    component: () => import("./containers/Page.vue")
  },    
  { 
    path: "/noticias/:slug", 
    component: () => import("./containers/Noticia.vue")
  },    
  { 
    path: "/busca/", 
    component: () => import("./containers/Search.vue")
  }    
];


// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes, // short for `routes: routes`
});

const app = createApp({
  render: () => h(App),
});

app.mixin(titleMixin);
app.mixin(metaTagMixin);
app.mixin(gtmTagsMixin);
app.mixin(Cookies);
app.use(router);
app.mount("#app");
app.use(VueTheMask);
app.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true
});
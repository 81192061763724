function getMetaTags (vm) {
    const { metaTags } = vm.$options    
    if (metaTags) {
      return typeof metaTags === 'function'
        ? metaTags.call(vm)
        : metaTags
    }
  }
  export default {
    created () {
      const metaTags = getMetaTags(this)
      if (metaTags) {
        let actualMetaTags = document.head.querySelectorAll('meta');
        const metaTagsArray = Array.from(actualMetaTags);                
        metaTags.forEach(metaTag => {
            
            const meta = document.createElement('meta')
            meta.setAttribute('name', metaTag.name)
            meta.setAttribute('content', metaTag.content)
            //if metatagsarray contains the same name and content, then don't add it again
            
            if (!metaTagsArray.some(metaTag => metaTag.getAttribute('name') === meta.getAttribute('name') && metaTag.getAttribute('content') === meta.getAttribute('content'))) {
                document.head.appendChild(meta);
            }
            
        })
      }
    }
  }